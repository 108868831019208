import React from "react";

const Footer = () => {
    return (
      <section id='footer' className='min-h-[10vh] lg:min-h-[10vh] flex items-center'>
        <div className='container mx-auto'>
            <p className="footer">&copy; 2023 <span className="copyright-text-color">
               Alan Nadelsticher</span></p>
        </div>
      </section>
      );
    };
  
  export default Footer;