import React from 'react';

// Motion
import {motion} from "framer-motion";

// Variant
import {fadeIn} from "../variants";

import Resume from "../assets/Alan's Resume copy.pdf";
// images
// import Logo from "../assets/logo.svg";

const Header = () => {
  return <header className='py-8'>
    <div className="container mx-auto"> 
      <div className='flex-1 items-center'>
        {/* Button */}
        <a href={Resume} target="_blank">
          <motion.button 
            className='btn btn-sm'
            variants={fadeIn("up", 2.0)} initial="hidden" whileInView={"show"}
            viewport={{once: false, amount: 0.7}} >
            My CV
          </motion.button>
        </a>
      </div>
    </div>
  </header>;
};

export default Header;
