import React from 'react';
// CountUp
import CountUp from 'react-countup';
// Intersection Observer Hook
import { useInView } from 'react-intersection-observer';
// Motion
import {motion} from 'framer-motion';
// Variant
import {fadeIn} from '../variants'

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  })
  return (
  <section id='about' className='section' ref={ref}>
    <div className="container mx-auto">
      <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center
        lg:gap-x-20 lg:gap-y-0 h-screen'>
        {/* {Img} */}
        <motion.div 
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 bg-about bg-contain bg-no-repeat
          h-[450px] mix-blend-lighten bg-top '>
        </motion.div>
        {/* {Text} */}
        <motion.div
          variants={fadeIn("left", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 text-about'>
          <h2 className='h2 text-accent'>Nice to Meet You</h2>
          <h3 className='h3 mb-4'>Innovative and diligent, with ample knowledge
          in numerous sought-after technologies.</h3>
          <p className='mb-6'>
            Currently a devops engineer with knowledge in cloud computing, software development, and data science. 
            Former division one student-athlete, and now Business Technology graduate, of The University of Miami.
          </p>
          {/* {stats} */}
          <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
            <div>
              <div className='text-[34px] font-tertiary text-gradient mb-2'>
                {
                  inView ? <CountUp start={0} end={50} duration={3.5}/> : null}
                  +
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                In-depth <br/>
                Projects <br/>
                Completed
              </div>
            </div>
            <div>
              <div className='text-[34px] font-tertiary text-gradient mb-2'>
                {
                  inView ? <CountUp start={0} end={30} duration={3}/> : null}
                  +
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                In Demand <br/>
                Skills
              </div>
            </div>
            <div>
              <div className='text-[34px] font-tertiary text-gradient mb-2'>
                {
                  inView ? <CountUp start={0} end={5} duration={2}/> : null}
                  +
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Industry <br/>
                Recognized <br/>
                Certifications
              </div>
            </div>
          </div>
          {/* <div className='flex gap-x-8 items-center'>
            <button className='btn btn-lg'>Contact Me</button>
            <a href='#' className='text-gradient btn-link'>
              My Portfolio
            </a>
          </div> */}
        </motion.div>
      </div>
    </div>
  </section>
  );
};

export default About;
