import React from 'react';
// Motion
import {motion} from 'framer-motion';
// Variants
import { fadeIn } from '../variants'; 
// Icons
import {FaGithub, FaYoutube, FaDribbble, FaLinkedin} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import {HiDocumentText} from "react-icons/hi";
// Resume
import Resume from "../assets/Alan's Resume copy.pdf";

const Contact = () => {
  return (
    <section id='contact' className='py-16 lg:section'>
    <div className='container mx-auto'>
      <div className='flex flex-col lg:flex-row'>
        {/* Text */}
        <motion.div 
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}} 
          className='flex-1 flex justify-start items-center text-lg'>
            <div>
              <h2 className='text-xl lg:text-[35px] uppercase text-accent mb-2 font-small
               tracking-wide h2 contact-text-one'>Find out more</h2>
              <h3 className='text-[30px] lg:text-[30px] leading-none 
              mb-12 contact-text-two h3 font-small '>My socials, and relevant <br/> information you might need,  
               <br/> all in one place.</h3>
            </div>
        </motion.div>
        {/* Form */}
        <motion.form 
          variants={fadeIn("left", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}} 
          className='flex-1 rounded-2xl border flex flex-col gap-y-4 p-6 pb-2'>
            <a href='https://github.com/alannadels' target="_blank">
            <div 
            className='bg-transparent border-b py-9 outline-none w-full
            focus:border-accent transition-all text-gradient text-[26px]'>
            GitHub<div className='github'><FaGithub /></div>
            </div>
            </a>
            <a href='https://www.linkedin.com/in/alannadelsticher/' target="_blank">
            <div 
            className='bg-transparent border-b py-9 outline-none w-full 
            focus:border-accent transition-all text-gradient text-[26px]'>
            LinkedIn <div className='github'><FaLinkedin /></div>
            </div>
            </a>
            <a href={Resume} target="_blank">
            <div 
            className='bg-transparent border-b py-9 outline-none w-full 
            focus:border-accent transition-all text-gradient text-[26px]'>
            My Resume <div className='github'><HiDocumentText /></div>
            </div>
            </a>
            <a href='mailto:alannmoco@gmail.com' target="_blank">
            <div 
            className='bg-transparent py-9 outline-none w-full 
            focus:border-accent transition-all text-gradient text-[26px]'>
            Email Me <div className='github'><MdEmail /></div>
            </div>
            </a>
        </motion.form>
      </div>
    </div>
    </section>
    );
  };
export default Contact;
