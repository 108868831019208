import React from 'react';

// Images
import image from "../assets/Intro-img.png";

// Icons
import {FaGithub, FaYoutube, FaDribbble} from "react-icons/fa";

// Type Animation
import {TypeAnimation} from 'react-type-animation';

// Motion
import {motion} from "framer-motion";

// Variant
import {fadeIn} from "../variants";

const Banner = () => {
  return (
    <section id='home' className='min-h-[85vh] lg:min-h-[78vh] flex items-center'>
        <div className='container mx-auto'>
          <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
            {/* text */}
            <div className='flex1 text-center font-secondary lg:text-left'>
              <motion.h1 variants={fadeIn("up", 0.3)} initial="hidden" whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
                Alan <span>Nadelsticher</span>
              </motion.h1>
              <motion.div
                variants={fadeIn("up", 0.5)} initial="hidden" whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                className='mb-6 text-[36px] lg:text-[60px] font-secondary 
                  font-semibold uppercase leading-[1]'>
                  <span className='text-white mr-4'>A</span>
                  <TypeAnimation sequence={[
                    "DevOps Engineer",
                    2000,
                    "Cloud Architect",
                    2000,
                    "Developer",
                    2000,
                    "Data Scientist",
                    2000,
                  ]}
                  speed={50}
                  className="text-accent"
                  wrapper='span'
                  repeat={Infinity}
                  />
              </motion.div>
              <motion.p
                variants={fadeIn("up", 0.7)} initial="hidden" whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  
                className='mb-8 max-w-lg mx-auto lg:mx-0'>
                  Welcome to my website! Feel free to look
                  around. There might be a thing or two that catch your attention.
              </motion.p>
              <motion.div 
                variants={fadeIn("up", 0.9)} initial="hidden" whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
                <a href='#contact'>
                  <button className='btn btn-lg'>Contact Me</button>
                </a>
                <a href='#services' className='text-gradient btn-link'>
                  What I Do
                </a>
              </motion.div>
                {/* Socials */}
              {/* <motion.div
                variants={fadeIn("left", 1.1)} initial="hidden" whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0 '>
                  <a href='#'>
                    <FaYoutube/>
                  </a>
                  <a href='#'>
                    <FaGithub/>
                  </a>
                  <a href='#'>
                    <FaDribbble/>
                  </a>
              </motion.div> */}
            </div>
            {/* Self Image */}
            <motion.div 
              variants={fadeIn("down", 0.5)} initial="hidden" whileInView={"show"}
              viewport={{once: false, amount: 0.7}} 
              className='hidden lg:flex flex-2 max-w-[320px] lg:max-w-[482px]'>
                <img className='profileImg' src={image} alt=''/>
            </motion.div>
          </div>
        </div>
    </section>
    );
  };

export default Banner;
