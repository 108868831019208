import React from 'react';
// Motion
import {motion} from 'framer-motion'
// Variants
import { fadeIn } from '../variants';
// Images
import Img1 from '../assets/demo - Mr._Jumpman.gif'
import Img2 from '../assets/demo - AI_Chatbot.gif'
import Img3 from '../assets/demo - Amazon_bot.gif'
import Img4 from '../assets/demo - Infinity_learning.gif'
import Img5 from '../assets/demo - Laptop_Price_Prediction.gif'
import Img6 from '../assets/demo - API_Quiz_App.gif'

const Work = () => {
  return (
    <section id='work' className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}} 
            className='flex-1 flex flex-col gap-y-0 mb-10 lg:mb-0'>
              {/* Text */}
              <div className='text-margins'>
                <h2 className='h2 leading-tight text-accent'>
                  My Work <br/>
                </h2>
                <h3 className='max-w-sm mb-10 h3'>Some of the projects I've enjoyed working on the most.
                </h3>
                <a href='https://github.com/alannadels' target="_blank">
                  <button className='btn btn-sm'>More About Me</button>
                </a>
              </div>
              {/* Image */}
            <a target="_blank">
              <div className='group relative overflow-hidden border-2 border-white/50 
                rounded-xl img-margins img-scale img-responsive'>
                {/* Overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all 
                duration-300'>
                </div>
                {/* Img */}
                <img className='group-hover:scale-125 transition-all 
                duration-500' src={Img1} alt='' />

                {/* Pretitle */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-24 
                transition-all duration-500 z-50'>
                  <span className='text-gradient'>Game Development</span>
                </div>
                {/* Title */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 
                transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white'>Mr. JumpMan</span>
                </div>
              </div>
            </a>
          </motion.div>
          <motion.div 
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 flex flex-col gap-y-6 gif-scale'>
              {/* Image */}
            <a target="_blank">
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                {/* Overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all 
                duration-300'>
                </div>
                {/* Img */}
                <img className='group-hover:scale-125 transition-all 
                duration-500' src={Img2} alt='' />
                {/* Pretitle */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-24 
                transition-all duration-500 z-50'>
                  <span className='text-gradient'>Software Development</span>
                </div>
                {/* Title */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 
                transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white'>Artificial Intelligence Chatbot</span>
                </div>
              </div>
            </a>
              {/* Image */}
            <a target="_blank">
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl img-margins'>
                {/* Overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all 
                duration-300'>
                </div>
                {/* Img */}
                <img className='group-hover:scale-125 transition-all 
                duration-500' src={Img3} alt='' />
                {/* Pretitle */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-24 
                transition-all duration-500 z-50'>
                  <span className='text-gradient'>Software Development</span>
                </div>
                {/* Title */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 
                transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white'>Automated Amazon Shopping Bot</span>
                </div>
              </div>
              </a>
          </motion.div>
          <motion.div 
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 flex flex-col gap-y-6 gif-scale gif-responsive'>
              {/* Image */}
            <a target="_blank">
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                {/* Overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all 
                duration-300'>
                </div>
                {/* Img */}
                <img className='group-hover:scale-125 transition-all 
                duration-500' src={Img5} alt='' />
                {/* Pretitle */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-24 
                transition-all duration-500 z-50'>
                  <span className='text-gradient'>Data Science</span>
                </div>
                {/* Title */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 
                transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white'>Laptop Market Analysis and Prediction</span>
                </div>
              </div>
            </a>
              {/* Image */}
            <a target="_blank">
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl img-margins'>
                {/* Overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all 
                duration-300'>
                </div>
                {/* Img */}
                <img className='group-hover:scale-125 transition-all 
                duration-500' src={Img6} alt='' />
                {/* Pretitle */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-24 
                transition-all duration-500 z-50'>
                  <span className='text-gradient'>Software Development</span>
                </div>
                {/* Title */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 
                transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white'>API Quiz App</span>
                </div>
              </div>
              </a>
          </motion.div>
        </div>
      </div>
    </section>
    );
  };

export default Work;
