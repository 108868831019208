import React from 'react';
// Icons
import { BsArrowUpRight } from 'react-icons/bs';
import {FaGithub, FaYoutube, FaDribbble, FaLinkedin} from "react-icons/fa";
// Motion
import {motion} from 'framer-motion';
// Variants
import { fadeIn } from '../variants';

// Services Data
const services = [
  {
    name: "Cloud Computing",
    description: 
      'DevOps | Solution Architecture | Cloud Engineering ',
    // link: 'Learn More'
  },

  {
    name: "Software Development",
    description:
      'Artificial Intelligence | Machine Learning | APIs | Game Development | Software Management',
    // link: "API's, Machine Learning, Artificial Intelligence."
  },

  {
    name: "Data Science",
    description: 
      'Machine Learning | Data Analysis | Data Visualization | Statistical Analysis',
    // link: 'Learn More'
  },

  {
    name: "Web Development",
    description: 
      'Full Stack Development',
    // link: ""
  },
]

const Services = () => {
  return (
    <section id='services' className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          {/* Text and Img */}
          <motion.div 
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 lg:bg-services lg:bg-bottom
            bg-no-repeat mix-blend-lighten mb-25'>
              <h2 className='h2 text-accent mb-6'>Areas of Knowledge</h2>
              <h3 className='h3 max-w-[455px] mg-bottom-services'>
                My expertise encompasses the following aspects
                of technology.
              </h3>
              {/* <div className='logo-align'>
              <a href='https://github.com/alannadels' target='_blank' className='btn w-9 h-9 mb-[0px] flex 
              justify-center items-center'>
                <FaGithub />
              </a>
              </div> */}
          </motion.div>
          {/* Services */}
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1'>
              {/* Services List */}
              <div>
                {services.map((service, index)=> {
                  // Destructure Service
                  const {name, description, link} = service;
                  return (
                  <div className='h-[120px] mb-[35px] mt-[5px] flex' key={index}>
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary 
                      font-semibold mb-6'>{name}</h4>
                      <p className='font-secondary leading-tight text-gradient'>{description}</p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      {/* <a href='https://github.com/alannadels' target='_blank' className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
                        <FaGithub />
                      </a> */}
                      {/* <div className='text-gradient text-sm margin-service-text'>
                        {link}
                      </div> */}
                    </div>
                    </div>
                  );
                })}
              </div>
          </motion.div>
        </div>
      </div>
    </section>
    );
  };

export default Services;
